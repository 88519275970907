/* This example requires Tailwind CSS v2.0+ */
import { ChevronRightIcon, HomeIcon, ServerIcon } from '@heroicons/react/solid'
import { ReactComponent as LogoDepartment } from '../../../Asset/Logo/department-logo.svg'
import { Link } from 'react-router-dom'

export default function NotFound() {
    const links = [
        {
            title: 'Portal Utama', description: 'Portal utama Mitras DUDI. menampilkan informasi utama Mitras DUDI', icon: HomeIcon,
            action: 'https://mitrasdudi.id'
        },
        {
            title: 'Dashboard Mitras DUDI', description: 'Portal dashboard Mitras DUDI. Kebutuhan internal tim Mitras DUDI', icon: ServerIcon,
            action: 'https://dashboard.mitrasdudi.id'
        },
    ]
    return (
        <div className="bg-white">
            <main className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex-shrink-0 flex pt-16 items-center justify-center">
                    <LogoDepartment />
                </div>
                <div className="max-w-xl mx-auto py-16 sm:py-24">
                    <div className="text-center">
                        <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide">404 error</p>
                        <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                            Halaman tidak ditemukan.
                        </h1>
                        <p className="mt-2 text-lg text-gray-500">Halaman yang coba anda kunjungi kemungkinan sudah tidak ada atau sedang masa pengembangan. Silahkan kontak admin jika anda yakin ada.</p>
                    </div>
                    <div className="mt-12">
                        <h2 className="text-sm font-semibold text-gray-500 tracking-wide uppercase">Halaman rekomendasi</h2>
                        <ul className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                            {links.map((link, linkIdx) => (
                                <li key={linkIdx} onClick={typeof link.action === 'function' ? link.action : null} className="">
                                    <a className='relative py-6 flex items-start space-x-4' href={typeof link.action === 'string' ? link.action : null}>
                                        <div className="flex-shrink-0">
                                            <span className="flex items-center justify-center h-12 w-12 rounded-lg bg-indigo-50">
                                                <link.icon className="h-6 w-6 text-indigo-700" aria-hidden="true" />
                                            </span>
                                        </div>
                                        <div className="min-w-0 flex-1">
                                            <h3 className="text-base font-medium text-gray-900">
                                                <span className="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                    <button className="focus:outline-none">
                                                        <span className="absolute inset-0" aria-hidden="true" />
                                                        {link.title}
                                                    </button>
                                                </span>
                                            </h3>
                                            <p className="text-base text-gray-500">{link.description}</p>
                                        </div>
                                        <div className="flex-shrink-0 self-center">
                                            <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </div>
                                    </a>
                                </li>
                            ))}
                        </ul>
                        <div className="mt-8">
                            <Link to="/" className="text-base font-medium text-indigo-600 hover:text-indigo-500">
                                atau balik ke beranda<span aria-hidden="true"> &rarr;</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </main>
            <footer className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                <div className="border-t border-gray-200 py-12 text-center md:flex md:justify-between">
                    <p className="text-base text-gray-400">&copy; andryluthfi, Inc. All rights reserved.</p>
                    <div className="mt-6 flex justify-center space-x-8 md:mt-0">

                    </div>
                </div>
            </footer>
        </div>
    )
}
