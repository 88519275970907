import { ReactComponent as Logo } from '../../../Asset/Logo/department-logo.svg'

export default function Home() {
    return (
        <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
            <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex-shrink-0 flex justify-center">
                    <a href="/" className="inline-flex">
                        <span className="sr-only">Workflow</span>
                        <Logo
                            className="h-30 w-auto"
                        />
                    </a>
                </div>
                <div className="py-16">
                    <div className="text-center">
                        <p className="text-5xl font-semibold text-orange-400 uppercase tracking-wide">Mohon Maaf</p>
                        <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl ">Laman ini sedang dalam perbaikan</h1>
                        <p className="mt-2 text-base text-gray-500">Anda dapat menghubungi administator untuk informasi lebih lanjut.</p>
                        <div className="mt-6">
                            <a href="mailto:mail@andryluthfi.com" className="text-base font-medium text-indigo-600 hover:text-indigo-500">
                                Kontak admin<span aria-hidden="true"> &rarr;</span>
                            </a>
                        </div>
                    </div>
                </div>

                <img src={require('../../../Asset/Construction.png')} />
            </main>
            <footer className="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                <nav className="flex justify-center space-x-4">
                    <a href="mailto:mail@andryluthfi.com" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                        Kontak support
                    </a>

                    <span className="inline-block border-l border-gray-300" aria-hidden="true" />
                    <a href="https://www.instagram.com/mitrasdudi" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                        Instagram
                    </a>
                    <span className="inline-block border-l border-gray-300" aria-hidden="true" />
                    <a href="https://www.youtube.com/channel/UC2LYkSxOBYlfySjEjUpLzQg" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                        YouTube
                    </a>
                    <span className="inline-block border-l border-gray-300" aria-hidden="true" />
                    <a href="https://www.facebook.com/mitrasdudikemendikbud" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                        Facebook
                    </a>
                </nav>
            </footer>
        </div>
    )
}