import './index.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'

import Home from './Static/Home';
import NotFound from './Static/NotFound';

import { Fragment } from 'react';

const queryClient = new QueryClient()


function Router() {
  return (
    <Fragment>
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Fragment>
  )
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </QueryClientProvider>
  );
}


export default App;
